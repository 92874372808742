import React from "react";

const Nft = () => (
  <>
  <div style={{height: "700px"}}>
    <iframe src="https://opensea.io/collection/air-cryptos?theme=dark&ref=0x0Dec784F9AE694AC1A7A2051Cbf1a62d1774978D" style={{width: '100%', height: '100%'}} title="air_cryptos_opensea"></iframe>
  </div>
  </>
);

export default Nft;
